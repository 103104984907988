.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-main{
  background-color: #D9AFD9;
  background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);
  height: 100vh;
  overflow-y: auto;
}
.card{
  background-color: #fbeaff;
  box-shadow: 0px 10px 40px -10px rgba(0, 64, 128, 0.2);
  transition: all 0.2s cubic-bezier(0.800, 0.000, 0.200, 1.000);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease-in-out;
}
.card:hover{
  -webkit-box-shadow: 0px 0px 15px 0px rgba(252, 252, 252, 1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(252, 252, 252, 1);
    box-shadow: 0px 0px 15px 0px rgba(252, 252, 252, 1);
    transform: translateY(-5px);
}

.card .btn:hover {
  opacity: 0.8;
}